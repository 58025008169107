<template>
  <div class="Titles py-6">
    <v-row>
      <h2 class="text-h4 ma-auto">{{ title }}</h2>
    </v-row>
    <v-row>
      <p class="text-subtitle-1 ma-auto">{{ subTitle }}</p>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>

</style>
