import { get, getPaginated } from '@/api/implementation/app';

function fetch() {
  return get(`material`);
}

function fetchAll(page, perPage) {
  return getPaginated('material', page, perPage);
}

export {
  fetch,
  fetchAll,
};
