<template>
  <div class="page-home">
    <FeaturedMaterials/>
    <FeaturedProductGroups/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import FeaturedMaterials from '../components/FeaturedMaterials.vue';
import FeaturedProductGroups from '../components/FeaturedProductGroups.vue';

export default {
  components: {
    FeaturedMaterials,
    FeaturedProductGroups,
  },
  name: 'home',
  data() {
    return {};
  },
  created() {
    this.$hero.$emit('setBreadcrumbs', false);
    this.$hero.$emit('setLarge');
    this.$hero.$emit('setCanSearch', true);

    this.setMaterialId(null);
    this.removeFilters();
  },
  beforeRouteLeave(to, from, next) {
    this.$hero.$emit('setSmall');
    next();
  },
  methods: {
    ...mapMutations({
      setMaterialId: 'filter/setMaterialId',
      removeFilters: 'filter/removeFilters',
    }),
  },
};
</script>

<style scoped lang="scss">

</style>
