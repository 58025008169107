<template>
  <v-card hover outlined>
    <v-img contain :src="media" aspect-ratio="1" width="100%"/>
    <v-card-title class="text-center">{{ product.name }}</v-card-title>
  </v-card>
</template>

<script>
import { getPlaceholder } from '@/application/util/imagePlaceholder.js';

export default {
  name: 'ProductGroup',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    media() {
      return this.product.mainImage?.url ?? getPlaceholder();
    },
  },
};
</script>
