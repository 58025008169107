<template>
  <v-col>
    <Title title="Onze categorieën" sub-title="Een greep uit onze categorieën" />
      <v-row justify="center">
        <v-col cols="6" sm="4" md="3" lg="2" v-for="material in materials" :key="material.id">
          <div @click="handleSelectMaterial(material)">
            <v-card tile elevation="0">
              <v-img
                contain
                :lazy-src="`${material.media}?size=10`"
                :src="material.media"
                class="material"
                aspect-ratio="1"
                width="100%"
              />
              <v-card-title class="pt-1 justify-center text-center">
                {{ material.name }}
              </v-card-title>
            </v-card>
          </div>
        </v-col>
      </v-row>
    <v-row justify="center" class="pt-5">
      <v-btn color="primary" :to="{ name: 'order' }">Bestel direct</v-btn>
    </v-row>
  </v-col>
</template>

<script>
import { mapMutations } from "vuex";
import Title from "./Title.vue";
import { fetchAll } from "../api/product/material";
import { getPlaceholder } from "@/application/util/imagePlaceholder.js";

export default {
  name: "FeaturedMaterials",
  components: { Title },
  data: () => ({
    materials: [],
  }),
  created() {
    this.getMaterials();
  },
  methods: {
    ...mapMutations({
      setMaterialId: "filter/setMaterialId",
      removeFilters: "filter/removeFilters",
    }),
    async getMaterials() {
      const response = await fetchAll(1, 6);

      this.materials = response.data.map((material) => {
        return {
          ...material,
          media: material.image?.url ?? getPlaceholder(),
        };
      });
    },
    handleSelectMaterial(material) {
      this.removeFilters();
      this.setMaterialId(material.id);
      this.$router.push({ name: "order" });
    },
  },
};
</script>

<style scoped>
.material {
  cursor: pointer;
}
</style>
