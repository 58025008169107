<template>
  <v-col>
    <Title title="Onze producten" sub-title="Een greep uit onze producten"/>
    <v-row justify-md="center">
      <v-col cols="6" sm="4" md="3" lg="2" v-for="product in products" :key="product.id">
        <div @click="openProduct(product)">
          <ProductGroup :product="product"/>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Title from '../components/Title.vue';
import ProductGroup from '../components/ProductGroup.vue';
import { fetchAll } from '../api/product/product.js';

export default {
  name: 'FeaturedProductGroups',
  components: {
    Title,
    ProductGroup,
  },
  data: () => ({
    products: [],
  }),
  created() {
    this.getProducts();
  },
  methods: {
    async getProducts() {
      const response = await fetchAll(1, 4);
      this.products = response.data;
    },
    openProduct(product) {
      this.$router.push({
        name: 'product',
        params: { code: product.code },
      });
    },
  },
};
</script>

<style scoped>

</style>
